html {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    overflow-y: scroll;
}

body {

    section {
        width: 100%;
        padding: 0 15px;
    }

    .p-message-detail {
        padding-left: 10px;
    }

    .text-title {
        font-weight: bold;
        font-size: 15px;
        color: #dd1f26;
        text-transform: uppercase;
    }

    .form-login {
        width: 100%;
        height: 100%;
        background-color: #dd1f26;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        color: #fff;

        font-size: 13px;

        .logo-login {
            object-fit: contain;
        }

        .input-login {
            width: 100%;
        }

        .btn-login {
            height: 40px;
            font-size: 16px;
            font-weight: 500;
            width: 100%;
            color: #6c757d;
            background-color: #fff !important;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        }

        .btn-login:focus {
            outline: 0;
        }

        .p-disabled {
            background: #607D8B !important;
            border: 1px solid #607D8B !important;
            color: #fff !important;
        }

        .p-inputgroup {
            border-color: #fff;

            .p-inputgroup-addon {
                background-color: #fff;
                padding: 8px 10px;

                border-color: #fff;

                .pi {
                    font-size: 18px;
                }
            }

            .p-inputgroup-addon:first-child,
            .p-inputgroup button:first-child,
            .p-inputgroup input:first-child,
            .p-inputgroup>.p-inputwrapper:first-child,
            .p-inputgroup>.p-inputwrapper:first-child>.p-inputtext {
                background-color: #fff;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            .p-inputgroup-addon:last-child,
            .p-inputgroup button:last-child,
            .p-inputgroup input:last-child,
            .p-inputgroup>.p-inputwrapper:last-child,
            .p-inputgroup>.p-inputwrapper:last-child>.p-inputtext {
                background-color: #fff;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            .p-inputtext {
                border-color: #fff;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                height: 40px;
                font-size: 16px;
                font-weight: 500;
            }

        }

        .p-inputtext {
            border-color: #fff;
        }

        .p-login-error {
            font-size: 13px;
        }
    }

    .nav-menu-bar {
        background-color: #dd1f26;
        padding: 0.5rem 0;

        .nav-item-back {
            padding: 0;
            border-color: #dc3545 !important;

            .btn-back {
                background-color: #dd1f26 !important;
                border-color: #dd1f26 !important;

                .pi {
                    font-size: 20px;
                }
            }
        }

        .nav-menu-logo-img {
            padding-left: 0.75rem;
        }

        .navbar-toggler {
            border: unset;
            color: #fff;
            padding: 0.25rem 0.75rem 0.25rem 0.75rem;

            .navbar-toggler-icon {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
            }
        }

        .nav-menu-logo {
            height: 24px;
            object-fit: contain;
        }

        .nav-item-coca {
            color: #fff;
            ;
            padding: 2.5px 10px;
            font-size: 14px;
        }

        .p-divider.p-divider-horizontal:before {
            border-color: #dc3545 !important;
        }

        @media (max-width: 768px) {
            .p-divider.p-divider-horizontal:first-child {
                margin-top: 5px;
            }

            .nav-item-coca {
                color: #fff;
                ;
                padding: 2.5px 15px;
                font-size: 14px;
            }

            .nav-item-coca:last-child {
                margin-bottom: 5.5px;
            }
        }
    }

    .form-event {
        width: 100%;
        height: 100%;
        padding: 0 15px;

        .item-event {
            padding: 10px 0 0 0;
            position: relative;
            cursor: pointer;
        }

        .p-card {
            border: 0.5px solid #d4d4d4;
            border-radius: 5px;
        }



        .btn-event {
            background-color: #dd1f26;
            border-color: #dd1f26;
            color: #fff;
            border-radius: 100%;
            width: 34px;
            height: 34px;
            font-weight: bolder;
        }

        .p-card .p-card-content {
            padding: 0;
        }

        .event-name {
            font-size: 16px;
            font-weight: bold;
            color: #dd1f26;
            text-transform: uppercase;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .event-desc {
            color: #000;
            overflow: hidden;
            white-space: nowrap;
            /* Don't forget this one */
            text-overflow: ellipsis;
            font-size: 14px;
        }
    }

    .name-event-store {
        display: none;
    }

    .search-store-input {
        padding-top: 20px;
    }

    .div-list-store {
        padding-top: 0;
    }

    .p-float-label label {
        font-size: 12px;
    }

    .search-store {
        position: fixed;
        width: 100%;
        background-color: #fff;
        top: 0;
        z-index: 999;
        padding-bottom: 7.5px;
        border-bottom: 1px solid #ccc;

        .p-float-label label {
            font-size: 12px;
        }

        .name-event-store {
            display: block;
        }

        .search-store-input {
            padding-top: 17.5px;
        }
    }

    .btn-search-store {
        background-color: #dd1f26;
        border-color: #dd1f26;
        color: #fff;
        font-weight: bold;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        cursor: pointer;
    }

    .alert {
        margin-bottom: 0;
        border: unset;
        border-radius: unset;
    }

    .alert-warning {
        background-color: #f39c12;
        color: #fff
    }

    .alert-heading {
        font-weight: bold;
    }

    .alert-success {
        background-color: #27ae60;
        color: #ecf0f1
    }

    .alert-secondary {
        background-color: #bdc3c7;
        color: #ecf0f1
    }

    .alert-danger {
        background-color: #c0392b;
        color: #ecf0f1
    }

    .alert-info {
        background-color: #16a085;
        color: #ecf0f1
    }

    .alert-dark {
        background-color: #2c3e50;
        color: #ecf0f1
    }



    .btn-circle {
        width: 34px;
        height: 34px;
        display: inline-block;
        border-radius: 100%;
        background-color: #dd1f26;
        border-color: #dd1f26;
        text-align: center;
        padding: 9.5px 7.5px 7.5px 7.5px;

        .pi {
            font-size: 16px;
            font-weight: bold;
            color: #fff
        }
    }


    .list-store {
        .p-dataview.p-dataview-list .p-dataview-content>.p-grid>div {
            border: unset;
        }

        .p-dataview-emptymessage {
            text-align: center;
        }

        .p-card .p-card-body {
            padding: 0;
            border: 1px solid #ced4da !important;
        }

        .p-card .p-card-footer {
            padding: 0;
        }

        .p-card .p-card-content {
            padding: 5px 0 0 0;
        }

        .p-customcard {

            .p-new-survey {
                border-radius: 5px;

                .p-card-body {
                    border-radius: 5px;
                }
            }

            .p-card {
                border-color: #c4c4c4;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                border-bottom: unset;
            }

            .p-card-body {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                border-bottom: unset;
            }
        }

        .p-new-survey {
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
            border-radius: 5px;
            overflow: hidden;
        }

        .p-card-survey {
            // margin-top: -1px;
            border-top: unset;
            -moz-border-left-radius: 5px;
            -webkit-border-left-radius: 5px;
            border-top-left-radius: unset;
            border-top-right-radius: unset;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            overflow: hidden;

            .p-card-content {
                border-top: unset;
                padding: 0;
            }

            .p-card {
                border-color: #c4c4c4;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            .p-card-body {
                border: unset !important;
            }
        }

        // .crop-text{
        //     overflow: hidden;
        //     white-space: nowrap; /* Don't forget this one */
        //     text-overflow: ellipsis;
        // }

        span {
            font-size: 12px;
        }

        .store-name {
            color: #dd1f26;
            font-weight: bold;
        }

        .store-code {
            font-weight: 500;
        }

        .icon-store {
            color: #dd1f26;
            font-size: 30px;
        }

        .icon-store-alt {
            color: #607D8B;
            font-size: 30px;
        }
    }



    .survey-audit {
        color: #495057;

        .p-card {
            border: 0.5px solid #d4d4d4;
        }

        .p-card-title {
            color: #dd1f26;
            font-size: 14px;
            margin: 0;
            padding-top: 10px;
        }

        .info-store-survey {
            .p-card-title {
                color: #495057;
            }
        }

        .p-card .p-card-body {
            padding: 0px 15px;

            .p-card-content {
                padding: 0 0 15px 0;
            }
        }

        .p-fieldset .p-fieldset-content {
            padding: 0px 15px 10px 15px;
            font-size: 12px;
        }

        .survey-capture {
            width: 100%;
            height: 100%;
            display: table-cell;
            vertical-align: middle;
            background-color: transparent;
            border: 1px dashed transparent;
            color: #d4d4d4;

            .pi {
                font-size: 3rem;
            }
        }
    }

    .text-title-group {
        font-weight: 500;
        font-size: 15px;
        text-transform: uppercase;
        color: #495057;
    }

    .btn-add-survey {
        color: #ffffff !important;
        background: #D32F2F !important;
        border: 1px solid #D32F2F !important;
        padding: 0.5rem 1rem !important;
        display: block;
        text-align: center;
        text-transform: uppercase;
    }

    .detail-store {
        .p-card {
            border: 0.5px solid #d4d4d4;
        }

        .p-card-title {
            color: #495057;
            font-size: 14px;
            margin: 0;
            padding-top: 10px;
        }

        .p-card .p-card-body {
            padding: 0px 15px;

            .p-card-content {
                padding: 0 0 15px 0;
            }
        }

        .btn-add-survey:hover {
            text-decoration: unset;
        }

        .icon-range {
            width: 40px;
            height: 40px;
            object-fit: contain;
        }


    }

    .survey-images {
        .lightbox-container {
            display: flex;
            flex-wrap: wrap;
        }

        .text-label {
            position: absolute;
            bottom: 0;
            text-align: center;
            color: #000;
            border-top-right-radius: 10px;
            padding: 0px 8.5px 1px 5px;
            background: #fff;
            opacity: 0.7;
            text-transform: uppercase;
        }
    }

    .webcam-upload {

        // display: flex;
        // text-align: center;        
        .p-dialog-header {
            padding: 0.5rem 1.5rem;
            // align-items: center;            
        }

        .p-dialog-content {
            padding: 0 1.5rem 1rem 1.5rem
        }
    }

    .text-hightlight {
        color: #D32F2F !important;
    }

    hr {
        margin-top: 0;
        margin-bottom: 0;
        border-top: 0.25px solid rgba(0, 0, 0, 0.1);
    }

    .footer-page {
        width: 100%;
        font-family: Arial, Helvetica, sans-serif;
        padding: 2.5px 10px;
        font-size: 12px;
        background-color: #fff;
        // position: fixed;
        // color: #fff;
        // margin-top: -25px;

        .logo-ten-software {
            background-color: #fff;

            img {
                height: 20px;
                object-fit: "contain"
            }
        }
    }
}

.p-button-label {
    pointer-events: none;
}

.btn-disable {
    pointer-events: none;
    filter: grayscale(100%);
}